<template>
    <div v-if="$slots.topCard" v-bind="$attrs" class="border shadow shadow-sm p-4 my-2 read-friendly-card" :class="{ 'bg-alice': props.isEdit, 'bg-white': !props.isEdit }">
        <slot name="topCard" :props="props"></slot>
    </div>
    <div 
        v-if="$slots.default" 
        v-bind="$attrs" 
        class="border shadow shadow-sm bg-white read-friendly-card d-flex flex-column" 
        :class="{ 'pt-2 my-4': props.isEdit, 'p-4 my-2': !props.isEdit, 'pb-0 px-0': props.isEdit && props.hasDataGrid, 'p-4': !props.hasDataGrid }"
        >
        <slot :props="props"></slot>
    </div>
</template>

<script setup lang="ts">
    export interface Props {
        isEdit?: boolean,
        hasDataGrid?: boolean
    }
    const props = withDefaults(defineProps<Props>(),{
        isEdit: false,
        hasDataGrid: true
    })
</script>

<style scoped>
.bg-alice {
    background-color : aliceblue ;
}
</style>

<style>
.read-friendly-card .o365-data-grid{
    border-radius: 0px!important;
    max-height: 400px!important;
    min-height: 200px!important;
}
</style>